var Tegeldepot = Tegeldepot || {};

Tegeldepot.CmsMenu = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);

        this.options = jQuery.extend({}, {
            cmsMenuSelector: '.cms-menu',
            cmsMenuItemSelector: '.cms-menu a',
        }, options);

        this.hookEvents();
    }

    hookEvents() {
        const path = window.location.pathname;

        jQuery(this.options.cmsMenuItemSelector).each(function () {
            const url = jQuery(this).attr('href');
            const urlSlash = url + '/';

            if (path === url || path === urlSlash) {
                jQuery(this).addClass('current');
            } else if (path.indexOf(url) !== -1 && jQuery(this).hasClass('has-children')) {
                jQuery(this).addClass('open');
            }
        });
    }
};

jQuery(function () {
    new Tegeldepot.CmsMenu();
});